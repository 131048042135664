<template>
 <div class="lg:flex lg:h-full lg:flex-col">
  <header
   class="flex items-center gap-4 border-b border-gray-200 bg-gray-50 px-2 py-2 lg:flex-none shadow ring-1 ring-black ring-opacity-5 rounded-t-md">
   <div class="flex items-center gap-4">
    <div class="relative flex items-center rounded-md bg-white shadow-sm">
     <button
      type="button"
      @click="previousMonth"
      class="flex h-9 w-12 items-center justify-center rounded-l-md pr-1 text-gray-400 hover:text-gray-500 focus:relative">
      <span class="sr-only">Previous</span>
      <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
     </button>
     <div class="w-full text-center">
      <!-- <h1
       class="text-base font-semibold leading-6 text-gray-900 cursor-pointer"
       @click="toggleMonthPicker">
       <time :datetime="formattedDate">{{ formattedDate }}</time>
      </h1> -->

      <!-- Month Input (hidden by default) -->
      <input
       type="month"
       v-model="selectedMonthInput"
       @change="selectMonth"
       class="bg-white rounded-md text-base font-semibold leading-6 text-gray-900 border-0"
       @blur="toggleMonthPicker"
       :lang="$i18n.locale" />
     </div>

     <button
      type="button"
      @click="nextMonth"
      class="flex h-9 w-12 items-center justify-center rounded-r-md pl-1 text-gray-400 hover:text-gray-500 focus:relative">
      <span class="sr-only">Next</span>
      <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
     </button>
    </div>
   </div>
  </header>
  <div
   class="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
   <div
    class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
    <!-- Weekdays header -->
    <div v-for="day in weekDays" :key="day" class="bg-white py-2">
     {{ day }}
    </div>
   </div>
   <div class="flex-auto">
    <div class="grid grid-cols-7 gap-px">
     <div
      v-for="(day, index) in daysInMonth"
      :key="index"
      class="relative bg-white px-3 py-2"
      :class="{ '': day.currentMonth, 'text-gray-400': !day.currentMonth }">
      <time
       :datetime="day.date"
       :class="{ 'font-bold text-indigo-700': isToday(day.date) }">
       {{ day.day }}
      </time>
      <div
       v-for="event in day.events"
       :key="event.id"
       @click="
        event.fullDate || event.dayOfWeek
         ? $emit('openEditFormTimeSlots', event)
         : $emit(
            'openEditFormExceptions',
            event.id,
            event.reference,
            event.destination
           )
       "
       :class="[
        event.day ? 'bg-red-50 text-red-700' : 'bg-indigo-50 text-indigo-700',
        'mt-1 block rounded-lg px-2 py-1 text-xs font-semibold  cursor-pointer',
       ]">
       <span v-if="event.start && event.end"
        >{{ event.start?.slice(0, 5) }}h - {{ event.end?.slice(0, 5) }}h :</span
       >
       {{ event.reference }}
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import {
 addMonths,
 subMonths,
 format,
 startOfMonth,
 getDaysInMonth,
 addDays,
 getDay,
 startOfWeek,
 endOfWeek,
 endOfMonth,
 parse,
} from "date-fns";

const currentDate = ref(new Date());

const props = defineProps({
 callQueueTimeSlot: Array,
 callQueueExceptions: Array,
});

// Weekdays for the header
const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Format current date

// Get days in the current month and map events to them
const daysInMonth = computed(() => {
 const start = startOfWeek(startOfMonth(currentDate.value)); // Start from the first day of the first week of the month
 const end = endOfWeek(endOfMonth(currentDate.value)); // End at the last day of the last week of the month
 const days = [];

 let arr = props.callQueueTimeSlot.concat(props.callQueueExceptions);

 for (let day = start; day <= end; day = addDays(day, 1)) {
  const dayOfWeek = getDay(day); // Get the day of the week (0 for Sunday, 1 for Monday, etc.)

  // Get events for this specific date or those recurring on this day of the week
  const events = arr.filter(span => {
   // If fullDate is specified, match it
   if (span.fullDate || span.day) {
    return (
     format(
      new Date(span.fullDate ? span.fullDate : span.day),
      "yyyy-MM-dd"
     ) === format(day, "yyyy-MM-dd")
    );
   }
   // If fullDate is null, match by dayOfWeek
   return span.fullDate === null && span.dayOfWeek === dayOfWeek;
  });
  let filteredEvents = events;
  if (events.filter(event => event?.day).length > 0) {
   filteredEvents = events.filter(event => event?.day);
  }
  days.push({
   date: format(day, "yyyy-MM-dd"),
   day: day.getDate(),
   events: filteredEvents,
   currentMonth: day.getMonth() === currentDate.value.getMonth(), // Indicate if the day is in the current month
  });
 }
 return days;
});

const isToday = date => format(new Date(), "yyyy-MM-dd") === date;

const isMonthPickerOpen = ref(false); // Controls the visibility of the month picker

const selectedMonthInput = ref(format(currentDate.value, "yyyy-MM")); // For the month input value

const formattedDate = computed(() => format(currentDate.value, "MMMM yyyy"));

const toggleMonthPicker = () => {
 isMonthPickerOpen.value = !isMonthPickerOpen.value;
 if (isMonthPickerOpen.value) {
  // Set the input value to the current date
  selectedMonthInput.value = format(currentDate.value, "yyyy-MM");
 }
};

const selectMonth = () => {
 const [year, month] = selectedMonthInput.value.split("-");
 currentDate.value = new Date(year, month - 1, 1);
 isMonthPickerOpen.value = false;
};

const nextMonth = () => {
 currentDate.value = addMonths(currentDate.value, 1);
 selectedMonthInput.value = format(currentDate.value, "yyyy-MM");
};

const previousMonth = () => {
 currentDate.value = subMonths(currentDate.value, 1);
 selectedMonthInput.value = format(currentDate.value, "yyyy-MM");
};

const goToToday = () => {
 currentDate.value = new Date();
 selectedMonthInput.value = format(currentDate.value, "yyyy-MM");
};
</script>

<style scoped></style>
