<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess" />

 <div class="pt-8">
  <div
   class="lg:flex lg:items-center lg:justify-between bg-white mx-8 p-4 rounded-md shadow">
   <div class="flex-1 min-w-0">
    <h2
     class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight sm:truncate">
     {{ callQueueDetail.name }}
    </h2>
    <div
     class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
     <div class="mt-2 flex items-center text-sm text-gray-500">
      <font-awesome-icon
       icon="phone"
       class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />

      {{ callQueueDetail.number ? callQueueDetail.number : $t("noNumber") }}
     </div>

     <div class="mt-2 flex items-center text-sm text-gray-500">
      <font-awesome-icon
       icon="user-group"
       class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
      <span
       v-if="this.callQueuesGroup"
       :key="this.key"
       @click="
        this.$router.push({
         name: 'CallQueuesGroupDetailExpert3cx',
         params: { account, id: this.callQueuesGroup.id },
        })
       "
       class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 cursor-pointer hover:text-gray-700">
       {{ this.callQueuesGroup.name }}
      </span>
      <span v-else class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
       {{ $t("none") }}
      </span>
     </div>
     <div class="mt-2 flex items-center text-sm text-gray-500">
      <font-awesome-icon
       icon="hashtag"
       class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
      {{ callQueueDetail.code ? callQueueDetail.code : $t("noCode") }}
     </div>
     <div class="mt-2 flex items-center text-sm text-gray-500">
      <CalendarIcon
       class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
       aria-hidden="true" />

      {{ $t("updatedDate") }}
      <span v-if="callQueueDetail.updated_at" class="ml-1 capitalize">
       {{ $d(callQueueDetail.updated_at, "shortText") }}
      </span>
     </div>
    </div>
   </div>
   <div
    class="flex divide-x divide-gray-100 bg-gray-400 border text-white rounded-md shadow-sm">
    <button
     @click="planningView = false"
     :class="[
      planningView ? '' : 'bg-gray-600 text-white',
      'rounded-l-md inline-flex items-center px-4 py-2 text-sm font-medium focus:outline-none hover:text-white hover:bg-gray-500',
     ]">
     <TableIcon class="h-5 w-5" aria-hidden="true" />
    </button>
    <button
     @click="planningView = true"
     :class="[
      !planningView ? '' : 'bg-gray-600 text-white',
      'rounded-r-md inline-flex items-center px-4 py-2 text-sm font-medium focus:outline-none hover:text-white hover:bg-gray-500',
     ]">
     <CalendarIcon class="h-5 w-5" aria-hidden="true" />
    </button>
   </div>
   <div v-if="this.callQueuesGroup" class="mt-5 flex lg:mt-0 lg:ml-4">
    <button
     type="button"
     class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
     @click="
      unlinkCallQueue(
       callQueueDetail.id,
       `File d'attente déliée avec succès`,
       `Déliez la file d'attente ${callQueueDetail.name}`,
       `Êtes-vous sûr de vouloir dissocier ce file d'attente ? Vous allez dissocier cette file d'attente de son groupe. Les créneaux horaires et les jours exceptionnels seront conservés mais les ajouts futurs dans un groupe de file d'attente ne seront pas transférés à cette file.`,
       'Délier',
       'Annuler'
      )
     ">
     <font-awesome-icon
      icon="link-slash"
      class="-ml-1 mr-2 h-5 w-5 text-gray-500" />
     Déliez du groupe
    </button>
   </div>
  </div>

  <div class="p-10">
   <div class="mt-10">
    <div
     class="pb-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
     <h3 class="text-lg leading-6 font-medium text-gray-900">
      {{ callQueueDetail.name }} -
      <span class="capitalize">{{
       planningView ? $t("planning view") : $t("callqueueTable.timeslot")
      }}</span>
     </h3>
     <div
      v-if="
       (userPermissions.permissions.includes('smart-routings.*') ||
        userPermissions.permissions.includes('smart-routings.create')) &&
       this.callQueueDetail
      "
      class="mt-3 sm:mt-0 sm:ml-4 flex gap-2 items-center"
      :key="this.openTimeSlotForm">
      <button
       v-if="!planningView"
       @click="
        (this.openTimeSlotForm = !this.openTimeSlotForm),
         (updateFormTimeSlots = false)
       "
       type="button"
       class="rounded ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium body__table--header text-white focus:outline-none">
       <svg
        v-if="!this.openTimeSlotForm"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 -ml-2 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M19 9l-7 7-7-7" />
       </svg>
       <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 -ml-2 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M5 15l7-7 7 7" />
       </svg>

       {{ $t("callqueueTable.addTimeSlot") }}
      </button>
      <Menu v-else as="div" class="relative inline-block text-left">
       <div>
        <MenuButton
         class="rounded flex gap-2 items-center px-4 py-2 border border-transparent text-sm font-medium body__table--header text-white focus:outline-none capitalize">
         {{ $t("options") }}
         <ChevronDownIcon class="h-5 w-5 text-white" aria-hidden="true" />
        </MenuButton>
       </div>

       <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
        <MenuItems
         class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
         <div class="py-1">
          <MenuItem v-slot="{ active }">
           <button
            @click="
             (this.openTimeSlotForm = !this.openTimeSlotForm),
              (updateFormTimeSlots = false)
            "
            :class="[
             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
             'block px-4 py-2 text-sm w-full text-left justify-start',
            ]">
            {{ $t("callqueueTable.addTimeSlot") }}
           </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
           <button
            @click="
             (this.openBankHolidaysForm = !this.openBankHolidaysForm),
              (this.openExceptionForm = false),
              (updateFormExceptions = false)
            "
            :class="[
             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
             'block px-4 py-2 text-sm w-full text-left justify-start',
            ]">
            {{ $t("callqueueTable.addException") }}
           </button>
          </MenuItem>
         </div>
        </MenuItems>
       </transition>
      </Menu>
     </div>
    </div>

    <div class="mt-5 rounded-md overflow-hidden">
     <CreateNewCallQueueTimeSlot
      @closeForm="this.closeTimeSlotForm()"
      :formatDateFrench="this.formatDateFrench"
      :openTimeSlotForm="this.openTimeSlotForm"
      :call_queue_id="this.callQueueDetail.id"
      :call_queue_time_spans="this.callQueueDetail.call_queue_time_spans"
      :call_queue_group="this.callQueuesGroup.name"
      :call_queue_detail="this.callQueueDetail"
      @activeBanner="
       (activeBanner = true),
        getCallQueueDetail(),
        getCallQueuesGroups(),
        closeTimeSlotForm(),
        (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event" />
     <CreateNewCallQueueTimeSlot
      @closeForm="this.closeExceptionForm()"
      :formatDateFrench="this.formatDateFrench"
      :fullFormatDateFrench="this.fullFormatDateFrench"
      :openExceptionForm="this.openExceptionForm"
      :openBankHolidaysForm="this.openBankHolidaysForm"
      :call_queue_id="this.callQueueDetail.id"
      :call_queue_exceptions="this.callQueueDetail.call_queue_exceptions"
      :call_queue_group="this.callQueuesGroup.name"
      :call_queue_detail="this.callQueueDetail"
      @activeBanner="
       (activeBanner = true),
        getCallQueueDetail(),
        getCallQueuesGroups(),
        this.closeExceptionForm(),
        (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event" />
    </div>
    <div v-if="updateFormTimeSlots" class="mb-5">
     <UpdateCallQueueDetailForm
      :title="$t('callqueue.updateTimeSlot')"
      :callQueueGroupTimeSlot="this.callQueueTimeSlot"
      :id="this.timeSpanId"
      :dayOfWeek="this.dayOfWeek"
      :fullDate="this.fullDate"
      :call_queue_time_spans="this.callQueueDetail.call_queue_time_spans"
      @closeForm="updateFormTimeSlots = false"
      @activeBanner="
       (activeBanner = true),
        getCallQueueDetail(),
        getCallQueuesGroups(),
        (updateFormTimeSlots = false),
        (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event" />
    </div>
    <div v-if="updateFormExceptions && planningView" class="mb-5">
     <UpdateCallQueueDetailForm
      :title="$t('callqueue.updateException')"
      :id="this.exceptionId"
      :formatDateFrench="this.formatDateFrench"
      :fullFormatDateFrench="this.fullFormatDateFrench"
      :call_queue_exceptions="this.callQueueDetail.call_queue_exceptions"
      @closeForm="updateFormExceptions = false"
      :exceptionReference="this.exceptionReference"
      :exceptionDestination="this.exceptionDestination"
      @activeBanner="
       (activeBanner = true),
        getCallQueueDetail(),
        getCallQueuesGroups(),
        (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event" />
    </div>
    <TimeSpanPlanning
     v-if="
      planningView &&
      !this.openTimeSlotForm &&
      !this.openExceptionForm &&
      !openBankHolidaysForm &&
      this.call_queue_time_spans.length > 0
     "
     :callQueueTimeSlot="call_queue_time_spans"
     :callQueueExceptions="call_queue_exceptions"
     @openEditFormTimeSlots="this.openUpdateFormTimeSlots($event)"
     @openEditFormExceptions="
      (...args) => this.openUpdateFormExceptions(...args)
     " />
    <div
     v-if="
      !this.openTimeSlotForm &&
      this.call_queue_time_spans.length > 0 &&
      !planningView
     "
     class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-md">
     <TablePagination
      :tableData="
       this.sortCallQueuesTimeSpansList
        ? this.sortedCallQueuesTimeSpans()
        : this.call_queue_time_spans
      "
      callqueueTable="Time Slots"
      @sortCallQueuesTimeSpans="
       sortCallQueuesTimeSpans($event),
        (this.sortExceptionsList = false),
        (this.sortCallQueuesTimeSpansList = true)
      "
      @openEditFormTimeSlots="this.openUpdateFormTimeSlots($event)"
      @activeBanner="
       (activeBanner = true),
        getCallQueueDetail(),
        getCallQueuesGroups(),
        (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event" />
    </div>
    <div
     v-show="!this.openTimeSlotForm && this.call_queue_time_spans.length === 0"
     class="flex justify-center pt-2">
     <div class="w-2/5 bg-yellow-50 mt-2 p-5">
      <div class="flex">
       <div class="flex-shrink-0">
        <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
       </div>
       <div class="ml-3">
        <h3 class="text-sm font-semibold text-yellow-800">
         {{ $t("warning.noDataFoundTitle") }}
        </h3>
        <div class="mt-2 text-sm text-yellow-700">
         <p>{{ $t("warning.noDataFoundTimeSlotDescription") }}</p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
   <div class="mt-10" v-if="!planningView">
    <div
     class="pb-3 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
     <h3 class="text-lg leading-6 font-medium text-gray-900">
      {{ callQueueDetail.name }} -
      <span class="capitalize">{{ $t("callqueueTable.exception") }}</span>
     </h3>
     <div
      v-if="
       userPermissions.permissions.includes('smart-routings.*') ||
       userPermissions.permissions.includes('smart-routings.create')
      "
      class="mt-3 flex sm:mt-0 sm:ml-4">
      <button
       @click="
        (this.openBankHolidaysForm = !this.openBankHolidaysForm),
         (this.openExceptionForm = false),
         (updateFormExceptions = false)
       "
       type="button"
       class="rounded inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300">
       {{ $t("callqueueTable.addExceptionTwelveMonths") }}
      </button>
      <button
       @click="
        (this.openExceptionForm = !this.openExceptionForm),
         (this.openBankHolidaysForm = false),
         (updateFormExceptions = false)
       "
       type="button"
       class="rounded ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium body__table--header text-white focus:outline-none">
       {{ $t("callqueueTable.addException") }}
      </button>
     </div>
    </div>
    <CreateNewCallQueueTimeSlot
     @closeForm="this.closeExceptionForm()"
     :formatDateFrench="this.formatDateFrench"
     :fullFormatDateFrench="this.fullFormatDateFrench"
     :openExceptionForm="this.openExceptionForm"
     :openBankHolidaysForm="this.openBankHolidaysForm"
     :call_queue_id="this.callQueueDetail.id"
     :call_queue_exceptions="this.callQueueDetail.call_queue_exceptions"
     :call_queue_group="this.callQueuesGroup.name"
     :call_queue_detail="this.callQueueDetail"
     @activeBanner="
      (activeBanner = true),
       getCallQueueDetail(),
       getCallQueuesGroups(),
       this.closeExceptionForm(),
       (key = !key)
     "
     @successMsg="successMsg = $event"
     @success="isSuccess = $event" />
    <div v-if="updateFormExceptions" class="mb-5">
     <UpdateCallQueueDetailForm
      :title="$t('callqueue.updateException')"
      :id="this.exceptionId"
      :formatDateFrench="this.formatDateFrench"
      :fullFormatDateFrench="this.fullFormatDateFrench"
      :call_queue_exceptions="this.callQueueDetail.call_queue_exceptions"
      @closeForm="updateFormExceptions = false"
      :exceptionReference="this.exceptionReference"
      :exceptionDestination="this.exceptionDestination"
      @activeBanner="
       (activeBanner = true),
        getCallQueueDetail(),
        getCallQueuesGroups(),
        (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event" />
    </div>
    <div
     class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-md">
     <TablePagination
      v-if="
       !this.openExceptionForm &&
       !this.openBankHolidaysForm &&
       this.call_queue_exceptions.length > 0
      "
      :tableData="
       sortExceptionsList
        ? this.sortedCallQueuesExceptions()
        : call_queue_exceptions
      "
      callqueueTable="Exceptions"
      @sortExceptions="
       sortCallQueuesExceptions($event),
        (this.sortExceptionsList = true),
        (this.sortCallQueuesTimeSpansList = false)
      "
      @openEditFormExceptions="
       (...args) => this.openUpdateFormExceptions(...args)
      "
      @activeBanner="
       (activeBanner = true),
        getCallQueueDetail(),
        getCallQueuesGroups(),
        (key = !key)
      "
      @successMsg="successMsg = $event"
      @success="isSuccess = $event" />
    </div>

    <div
     v-show="
      !this.openExceptionForm &&
      !this.openBankHolidaysForm &&
      this.call_queue_exceptions.length === 0
     "
     class="flex justify-center pt-2">
     <div class="w-2/5 bg-yellow-50 mt-2 p-5">
      <div class="flex">
       <div class="flex-shrink-0">
        <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
       </div>
       <div class="ml-3">
        <h3 class="text-sm font-semibold text-yellow-800">
         {{ $t("warning.noDataFoundTitle") }}
        </h3>
        <div class="mt-2 text-sm text-yellow-700">
         <p>{{ $t("warning.noDataFoundExceptionDescription") }}</p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>

 <ask-confirmation-dialog ref="askConfirmationDialog">
 </ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import TimeSpanPlanning from "../components/TimeSpanPlanning.vue";
import {
 ExclamationIcon,
 TableIcon,
 CalendarIcon,
 ChevronDownIcon,
} from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

import TablePagination from "../components/TablePagination";
import CreateNewCallQueueTimeSlot from "../components/CreateNewCallQueueTimeSlot.vue";
import EditCallQueueDetail from "../components/EditCallQueueDetail.vue";
import UpdateCallQueueDetailForm from "../components/UpdateCallQueueDetailForm.vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
import SuccessBanner from "../components/SuccessBanner.vue";

const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "CallQueuesDetail",
 components: {
  CalendarIcon,
  TableIcon,
  ExclamationIcon,
  ComfirmationDialog,
  AskConfirmationDialog,
  TablePagination,
  CreateNewCallQueueTimeSlot,
  EditCallQueueDetail,
  UpdateCallQueueDetailForm,
  SuccessBanner,
  TimeSpanPlanning,
  ChevronDownIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
 },
 props: ["account", "id"],
 data() {
  return {
   planningView: false,
   weekdays: [
    "",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
   ],
   callQueueDetail: [],
   callQueueTimeSlot: [],
   callQueuesGroup: {},
   callQueuesGroupId: "",
   call_queue_time_spans: [],
   call_queue_exceptions: [],
   twelveMonthsBankHolidays: {},
   openTimeSlotForm: false,
   openExceptionForm: false,
   openBankHolidaysForm: false,
   showMessage: false,
   currentSortTimeSpans: "id",
   currentSortExceptions: "id",
   currentSortDir: "asc",
   sortExceptionsList: false,
   sortCallQueuesTimeSpansList: false,
   updateFormExceptions: false,
   updateFormTimeSlots: false,
   timeSpanId: "",
   dayOfWeek: "",
   fullDate: "",
   exceptionId: "",
   exceptionReference: "",
   exceptionDestination: "",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   userPermissions,
  };
 },
 methods: {
  openUpdateFormExceptions(...args) {
   this.exceptionId = args[0];
   this.exceptionReference = args[1];
   this.exceptionDestination = args[2];
   this.openExceptionForm = false;
   this.updateFormExceptions = true;
  },
  openUpdateFormTimeSlots(data) {
   this.timeSpanId = data.id;
   //this.dayOfWeek = args[1];
   //this.fullDate = args[2];
   this.callQueueTimeSlot = data;
   this.updateFormTimeSlots = true;
   this.openTimeSlotForm = false;
  },
  async getcallQueueGroupDetail(id) {
   this.$emit("setLoading", true);
   this.call_queue_group_time_spans = [];
   this.call_queue_group_exceptions = [];
   const res = await axios.get(
    `${this.$cookie.getCookie(
     "API"
    )}/api/v1/callQueueGroups/${id}?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   this.$emit("setLoading", false);

   try {
    return res.data;
    // this.callQueueGroupDetail = res.data;
    this.call_queue_group_time_spans = res.data.call_queue_group_time_spans;
    this.call_queue_group_exceptions = res.data.call_queue_group_exceptions;
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  async getCallQueueDetail() {
   this.$emit("setLoading", true);
   this.call_queue_time_spans = [];
   this.call_queue_exceptions = [];
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/callQueues/${
     this.id
    }?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );
   try {
    this.callQueueDetail = res.data;
    if (this.callQueueDetail?.call_queue_group_id) {
     let data = await this.getcallQueueGroupDetail(
      this.callQueueDetail?.call_queue_group_id
     );
     this.call_queue_time_spans = data.call_queue_group_time_spans;
     this.call_queue_exceptions = data.call_queue_group_exceptions;
    } else {
     this.call_queue_time_spans = res.data.call_queue_time_spans;
     this.call_queue_exceptions = res.data.call_queue_exceptions;
    }
    console.log("getCallQueueDetail ~ res.data:", res.data);
    this.$emit("setLoading", false);
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  async getCallQueuesGroups() {
   this.$emit("setLoading", true);
   const res = await axios.get(
    `${this.$cookie.getCookie("API")}/api/v1/callQueueGroups?customerAccount=${
     this.account
    }`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );
   this.callQueuesGroups = res.data;
   console.log("getCallQueuesGroups ~ res.data:", res.data);
   this.$emit("setLoading", false);
   try {
    await this.getCallQueueDetail();
    if (this.callQueueDetail.call_queue_group_id) {
     this.callQueuesGroup = res.data.find(
      (callQueuesGroup, index) =>
       callQueuesGroup.id === this.callQueueDetail.call_queue_group_id
     );

     this.callQueuesGroupId = this.callQueuesGroup.id;
    } else {
     this.callQueuesGroup = "";
    }
    this.$emit("setLoading", false);
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  async deleteTimeSlotList(
   id,
   msg,
   title,
   confirmationMessage,
   confirmButton,
   goBack
  ) {
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueueTimeSpans/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then(response => {
      this.sendMessage(msg);
     })
     .catch(error => {
      this.errorHandling(error);
     });
   }
  },
  async deleteExceptionList(
   id,
   msg,
   title,
   confirmationMessage,
   confirmButton,
   goBack
  ) {
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueueExceptions/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then(response => {
      this.sendMessage(msg);
     })
     .catch(error => {
      this.errorHandling(error);
     });
   }
  },
  async unlinkCallQueue(
   id,
   msg,
   title,
   confirmationMessage,
   confirmButton,
   goBack
  ) {
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueues/unlink`,
    params: { customerAccount: this.account, callQueueId: id },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then(response => {
      this.activeBanner = true;
      this.isSuccess = true;
      this.successMsg = msg;
      this.key = !this.key;
      this.getCallQueuesGroups();
     })
     .catch(error => {
      this.errorHandling(error);
     });
   }
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   return dateCreated.toLocaleDateString("fr-FR");
  },
  fullFormatDateFrench(date) {
   const dateCreated = new Date(date);
   const frFormatDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
   };
   const formatDate = dateCreated.toLocaleDateString("fr-FR", frFormatDate);
   const capitalizeformatDate = formatDate.replace(/\w\S*/g, w =>
    w.replace(/^\w/, c => c.toUpperCase())
   );
   return capitalizeformatDate;
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  closeTimeSlotForm() {
   this.openTimeSlotForm = !this.openTimeSlotForm;
  },
  closeExceptionForm() {
   if (this.openExceptionForm != false) {
    this.openExceptionForm = !this.openExceptionForm;
   } else {
    this.openBankHolidaysForm = !this.openBankHolidaysForm;
   }
  },
  sortCallQueuesTimeSpans(s) {
   if (s === this.currentSortTimeSpans) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSortTimeSpans = s;
  },
  sortCallQueuesExceptions(s) {
   if (s === this.currentSortExceptions) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSortExceptions = s;
  },
  sortedCallQueuesTimeSpans() {
   return this.call_queue_time_spans.sort((a, b) => {
    let toBeSorted = this.currentSortTimeSpans;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted != "reference" && toBeSorted.length > 2) {
     if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted]))
      return -1 * modifier;
     if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted]))
      return 1 * modifier;
    } else if (toBeSorted.length === 2) {
     //sorted function for specific date and week days for callqueue
     if (a[toBeSorted[1]] < b[toBeSorted[1]]) return -1 * modifier;
     if (a[toBeSorted[1]] > b[toBeSorted[1]]) return 1 * modifier;
     if (a[toBeSorted[0]] == null || b[toBeSorted[0]] == null)
      return 1 * modifier;
     if (parseFloat(a[toBeSorted[0]]) < parseFloat(b[toBeSorted[0]]))
      return -1 * modifier;
     if (parseFloat(a[toBeSorted[0]]) > parseFloat(b[toBeSorted[0]]))
      return 1 * modifier;
    } else {
     if (a[toBeSorted] == null || b[toBeSorted] == null) return -1 * modifier;
     if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase())
      return -1 * modifier;
     if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase())
      return 1 * modifier;
    }
    return 0;
   });
  },
  sortedCallQueuesExceptions() {
   return this.call_queue_exceptions.sort((a, b) => {
    let toBeSorted = this.currentSortExceptions;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (toBeSorted === "destination" && toBeSorted.length > 2) {
     if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted]))
      return -1 * modifier;
     if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted]))
      return 1 * modifier;
    } else if (toBeSorted.length === 2) {
     //sorted function for specific date and week days for callqueue
     if (a[toBeSorted[1]] < b[toBeSorted[1]]) return -1 * modifier;
     if (a[toBeSorted[1]] > b[toBeSorted[1]]) return 1 * modifier;
     if (a[toBeSorted[0]] == null || b[toBeSorted[0]] == null)
      return 1 * modifier;
     if (parseFloat(a[toBeSorted[0]]) < parseFloat(b[toBeSorted[0]]))
      return -1 * modifier;
     if (parseFloat(a[toBeSorted[0]]) > parseFloat(b[toBeSorted[0]]))
      return 1 * modifier;
    } else {
     if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase())
      return -1 * modifier;
     if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase())
      return 1 * modifier;
    }
    return 0;
   });
  },
  filterCallQueuesTimeSpans() {
   return this.sortedCallQueuesTimeSpans().filter(list => {
    return (
     String(list.name)
      .toLowerCase()
      .includes(String(this.search).toLowerCase()) ||
     String(list.number).includes(String(this.search))
    );
   });
  },
 },
 mounted() {
  this.getCallQueueDetail();
  this.getCallQueuesGroups();
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style></style>
